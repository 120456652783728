.m-social-row
{
    display: flex;
    flex-direction: row;
    justify-content: center;
    &__link
    {
        flex-basis:32px;
        margin-left:2rem;
        margin-right:2rem;
    }
    &__icon
    {
        max-width:100%;
        opacity: 0.7;
        transition: all .2s ease-out;
        width:32px;
        height:32px;
    }
    &__icon:hover
    {
        opacity: 1;
        transition: all .2s ease-out;
    }

}