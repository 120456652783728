.o-swot {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    &__quadrant {
        background-color:$lightest-blue;
        border-radius:10px;
        flex-basis:calc(50% - 20px);
        padding:2rem;
        margin-top:10px;
        margin-bottom:10px;
        transition: all .3s ease-out;
        justify-content: flex-start;
        margin-left:7.5px;
        margin-right:7.5px;
        @media (max-width:767px) {
            flex-basis:calc(100% - 20px) 
        }
    }
    &__label {
        font-size: 2rem;
        margin-bottom:1.5rem;
        // font-family: $body-font;
    }
}