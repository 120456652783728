.a-body-image
{
    @extend .img-fluid;
    &--circle {
        border-radius:9999px;
    }
    &--rounded {
        border-radius:10px;
    }
    &--shadowed {
        -webkit-box-shadow: 2px 2px 5px 2px rgba(0,0,0,0.1);
        box-shadow: 0px 0px 5px 2px rgba(0,0,0,0.1);
    }

    &--mobile-narrow {
        @media (max-width:767px) {
            max-width: 60%;
            width: 60%;
            margin-left:auto;
            margin-right:auto;
            display: block;
        }
    }
}