.o-treegrid {
    width:100%;
    // white-space: nowrap;
    border-collapse: collapse;
    table-layout: fixed;
    counter-reset: col;
    &__cell {
        padding:1rem 0;
        border-bottom:solid 1px $light-gray;
        transition: all 0.2s ease-out;
        vertical-align: middle;
        &--actions {
            text-align: right;
        }
        &--header {
            background-color: $lightest-gray;
            border-bottom:solid 2px $light-medium-gray;
            color:$dark-gray;
            font-weight:$bold;
            padding:1.5rem 0;
        }
        &--32 {
            // width:32px;
            // padding-right:0;
            // padding-left:0;
        }
        &--32:first-child {

            // padding-left:1rem;

        }
        &--centered {
            text-align: center;
        }
        &--right-aligned {
            text-align: right;
        }
    }
    &__cell:last-child {
        transform: translateX(0px) !important;
    }
    &__col {
        &--48 {
            width:48px;
            text-align: center;
        }
        &--32 {
            width:32px;
        }
        &--small {
            width:7vw;
            @media (min-width:2200px) {
                width:6vw;
            }
        }
        &--medium {
            width:11vw ;
            @media (min-width:2200px) {
                width:8vw;
            }
        }
        &--wide {
            width:12vw;
            @media (min-width:2200px) {
                width:10vw;
            }
        }
        &--x-wide {
            width:14vw;
            @media (min-width:2200px) {
                width:12vw;
            }
        }
    }

    &__hidden-button {
        opacity: 0;
        transition: all 0.2s ease-out;
    }
    &__row {
        display: none;
        .o-treegrid__cell:last-child {
            padding-right:1rem;
        }
        &--striped-bg {
            background: repeating-linear-gradient(
                    45deg,
                    #ffffff,
                    #ffffff 2px,
                    #f5f5f5 2px,
                    #f5f5f5 4px
                );
            // .o-treegrid__cell {
            //     background: repeating-linear-gradient(
            //         45deg,
            //         #ffffff,
            //         #ffffff 2px,
            //         #f5f5f5 2px,
            //         #f5f5f5 4px
            //     );
            // }
        }
        &--shown {
            display: table-row;
        }
        &--no-border {
            .o-treegrid__cell {
                border-bottom:none;
            }
        }
    }
    &__row[aria-level="2"] {
        .o-treegrid__cell:nth-child(-n+2) {
            // padding-left:2rem;
            transform: translateX(2rem);
        }
    }
    &__row[aria-level="3"] {
        .o-treegrid__cell:nth-child(-n+2) {
            // padding-left:3.5rem;
            transform: translateX(3.5rem);
        }
    }
    &__row[aria-level="4"] {
        .o-treegrid__cell:nth-child(-n+2) {
            // padding-left:5rem;
            transform: translateX(5rem);
        }
    }
    &__row[aria-level="5"] {
        .o-treegrid__cell:nth-child(-n+2) {
            // padding-left:6.5rem;
            transform: translateX(6.5rem);
        }
    }
    &__row[aria-level="6"] {
        .o-treegrid__cell:nth-child(-n+2) {
            // padding-left:8rem;
            transform: translateX(8rem);
        }
    }
    &__row[aria-level="7"] {
        .o-treegrid__cell:nth-child(-n+2) {
            // padding-left:9.5rem;
            transform: translateX(9.5rem);
        }
    }
    &__row[aria-level="8"] {
        .o-treegrid__cell:nth-child(-n+2) {
            // padding-left:11rem;
            transform: translateX(11rem);
        }
    }
    &__row[aria-level="9"] {
        .o-treegrid__cell:nth-child(-n+2) {
            // padding-left:12.5rem;
            transform: translateX(12.5rem);
        }
    }
    &__row[aria-level="10"] {
        .o-treegrid__cell:nth-child(-n+2) {
            padding-left:14rem;
        }
    }
    &__row:hover {
        // .o-treegrid__cell {
        //     background-color:#F0F9F9;
        // }
        background-color:#F0F9F9;
        .o-treegrid__hidden-button {
            opacity: 1 !important;
        }
    }
    &--compact {
        .o-treegrid__cell {
            padding:0.5rem 0;
        }
    }

}

.o-treegrid:has(.o-treegrid__row--level-6.o-treegrid__row--shown) {
    .o-treegrid__col {
        &--48 {
            width:48px;
        }
        &--32 {
            width:32px;
        }
        &--medium {
            width:8vw ;
        }
        &--wide {
            width:15vw;
        }
        &--x-wide {
            min-width:20vw;
        }
    }
}
