.m-heading-row {
    display: flex;
    align-items: center;
    padding-top:1rem;
    padding-bottom:1rem;
    // border-bottom: solid 1px $light-gray;
    &__title {
        flex-basis:66.6%;
        width:66.6%;
    }
    &__actions {
        flex-basis:33.3%;
        width:33.3%;
    }
    &__actions-inner {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
    }
    &__action-item {
        display: inline-block;
    }
}