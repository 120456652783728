.o-sidebar {
    background-color:rgba(0, 121, 131, 0.15);
    border-right: solid 1px rgba(0,121,131,.075);
    padding: 1.5rem 1rem 1rem 1rem;
    height:calc(100vh - 80px);
    width:100%;
    position: -webkit-sticky; /* Safari */
    position: sticky;
    top: 80px;
    overflow-y: auto;
    &__group-heading {
        font-weight: 700!important;
        font-size: 1rem;
        border-bottom: solid 1px rgba(0,0,0,.15);
        // margin-top: 32px;
        width: 100%;
    }
}