.o-featured-video {
    &__title {
        @include heading-1;
        text-align: left;
        
    }
    &__description {
        color:$medium-gray;
        @include body;
        width:80%;
    }
    &__video {
        
        width: calc(60vh * 1.77);
        margin-left:auto;
        margin-right:auto;
        @media (max-width:1200px) {
            width:calc(100vw - 30px);
        }
        
    }
    &__tag {
        @include eyebrow;
        margin-top:1.5rem;
        display: block;
        margin-bottom:0.5rem;
      
        display: inline-block;
        margin-bottom: .5rem;
        vertical-align: text-bottom;

    }
    &__video-inner {
        position: relative;
        width:100%;
        height: 60vh;
        @media (max-width:1200px) {
            height:calc((100vw - 30px) / 1.77 );
        }
        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            // max-height: 70vh;
        }
    }
}